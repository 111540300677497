import { Injectable, Signal, computed, signal } from "@angular/core";
import { RegionDataService } from "./region-data.service";
import { Region } from "./region";
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { PathDecorator } from "../../shared/path-decorator";

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class RegionService {

  regions = signal<Region[]>([]);
  regionsWithPath: Signal<PathDecorator<Region>[]>;

  constructor(
    regionDataService: RegionDataService
  ) {
    regionDataService.getAll().pipe(untilDestroyed(this)).subscribe(regions => {
      this.regions.set(regions);
    });

    this.regionsWithPath =
     computed(() => this.regions().map(r => new PathDecorator(r, this.regions())));     
  }

  getRegionPath(regionId: string, includeSelf = true): string {
    const region = this.regionsWithPath().find(r => r.value.id === regionId);
    return region ? region.getPath(includeSelf) : '';
  }

}



