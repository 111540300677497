import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MediaService {

  // Service based on https://kinsta.com/blog/javascript-media-query/

  isMobile = signal(false);
  selectedSize = signal(ScreenSize.sm);

  screen = {
    sm: window.matchMedia('(min-width: 0px)'),
    md: window.matchMedia('(min-width: 768px)'),
    lg: window.matchMedia('(min-width: 1024px)'),
    xl: window.matchMedia('(min-width: 1280px)'),
    two_xl: window.matchMedia('(min-width: 1536px)'),
  };

  constructor() {
    // add media query events
    for (const [, mq] of Object.entries(this.screen)) {
      if (mq) mq.addEventListener('change', this.mqHandler.bind(this));
    }

    // first event
    this.mqHandler();
  }


  private mqHandler() {
    
    let size: keyof typeof this.screen | null = null;
    for (const [scr, mq] of Object.entries(this.screen)) {
      if (!mq || mq.matches) size = scr as keyof typeof this.screen;
    }

    const sizeMap = {
      sm: { size: ScreenSize.sm, isMobile: true },
      md: { size: ScreenSize.md, isMobile: true },
      lg: { size: ScreenSize.lg, isMobile: false },
      xl: { size: ScreenSize.xl, isMobile: false },
      two_xl: { size: ScreenSize.two_xl, isMobile: false },
    };

    const selectedSize = sizeMap[size ?? 'sm'];
    this.selectedSize.set(selectedSize.size);
    this.isMobile.set(selectedSize.isMobile);
  }

}


export enum ScreenSize {
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
  two_xl = 'two_xl',
}

// Breakpoints based of TailwindCSS
// https://tailwindcss.com/docs/responsive-design

// sm	640px	@media (min-width: 640px) { ... }
// md	768px	@media (min-width: 768px) { ... }
// lg	1024px	@media (min-width: 1024px) { ... }
// xl	1280px	@media (min-width: 1280px) { ... }
// 2xl	1536px	@media (min-width: 1536px) { ... }