import { Injectable } from '@angular/core';
import { Firestore } from '@angular/fire/firestore';
import { DataService } from '../../shared/service/data-service';
import { Administrative } from './administrative';

@Injectable({
  providedIn: 'root',
})
export class AdministrativeDataService extends DataService<Administrative> {

  constructor(firestore: Firestore) {
    super(firestore, 'administratives');
  }

}
