import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'volago-importance',
  templateUrl: './importance.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImportanceComponent implements OnInit {

  @Input() score = 1;

  importanceArray: unknown[] = [];

  ngOnInit(): void {
    this.validateAndSetScore();
  }

  private validateAndSetScore(): void {
    const score = Math.max(1, Math.min(this.score, 5));
    this.importanceArray = new Array(score);
  }
}
