import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { GeoPoint } from '@angular/fire/firestore';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class GeocodingService {
  private readonly API_URL = 'https://maps.googleapis.com/maps/api/geocode/json';
  private readonly PLACE_URL_BASE = 'https://www.google.com/maps/place/?q=place_id:';
  private readonly API_KEY = 'AIzaSyB9uPY9_S_IPZRzpKzlF9xDC1J1JOtujz4';

  constructor(private http: HttpClient) { }

  getGeolocation(name: string): Observable<GeocodingResult> {
    const encodedName = encodeURIComponent(name);
    const url = `${this.API_URL}?address=${encodedName}&key=${this.API_KEY}`;

    return this.http.get<GoogleGeocodingResult>(url)
      .pipe(map(response => {
        const results = response.results;
        if (results.length > 0) {
          const location = results[0].geometry.location;
          return {
            point: new GeoPoint(location.lat, location.lng),
            placeUrl: `${this.PLACE_URL_BASE}${results[0].place_id}`
           };
        }
        return EMPTY_GEOCODING;
      }));
  }
}

export interface GeocodingResult {
  point: GeoPoint;
  placeUrl: string;
}

const EMPTY_GEOCODING: GeocodingResult = {
  point: new GeoPoint(0, 0),
  placeUrl: ''
};


interface GoogleGeocodingResult {
  results: {
    geometry: {
      location: {
        lat: number;
        lng: number;
      };
      location_type: string;
      viewport: {
        northeast: {
          lat: number;
          lng: number;
        };
        southwest: {
          lat: number;
          lng: number;
        };
      };
    };
    place_id: string;
    types: string[];
  }[];
  status: string;
}