import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { ImportanceComponent } from './importance/importance.component';
import { TagListComponent } from './feature/tag/tag-list/tag-list.component';
import { MatChipsModule } from '@angular/material/chips';
import { DialogComponent } from './dialog/dialog.component';
import { MatDividerModule } from '@angular/material/divider';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDialogModule } from '@angular/material/dialog';
import { OpenInNewTabDirective } from './shared/directive/open-in-new-tab.directive';
import { EnumToArrayPipe } from './shared/pipe/enum-to-array.pipe';
import { MapComponent } from './components/map/map.component';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    MatChipsModule,
    MatDividerModule,
    FlexLayoutModule,
    MatDialogModule,
    LeafletModule,
    DialogComponent,
  ],
  declarations: [
    ImportanceComponent,
    TagListComponent,
    OpenInNewTabDirective,
    EnumToArrayPipe,
    MapComponent,
  ],
  exports: [
    ImportanceComponent,
    TagListComponent,
    DialogComponent,
    OpenInNewTabDirective,
    EnumToArrayPipe,
    MapComponent,
    MatIconModule
  ],
})
export class CoreModule {}
