import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Environment } from './environment';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ConfigAssetLoaderService {
  
  private readonly CONFIG_URL = 'assets/config/config.json';

  constructor(private http: HttpClient) { }

  loadEnvironment(environment: Environment): Observable<Environment> {
    return this.http.get<Environment>(this.CONFIG_URL).pipe(
      tap(c => {
        environment.stage = c.stage;
        environment.firebase = c.firebase;
        environment.urls = c.urls;
        environment.cloudinary = c.cloudinary;
      })
    );
  }
}
