<h1 mat-dialog-title>{{ title }}</h1>

<div mat-dialog-content>
  <ng-content select=".volago-dialog-content"></ng-content>
</div>

<mat-divider></mat-divider>

<div mat-dialog-actions align="end">
  <ng-content select=".volago-dialog-actions"></ng-content>
</div>