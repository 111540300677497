import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { control, Control, latLng, Layer, Map, MapOptions, tileLayer } from 'leaflet';
import { LocalStorageService } from '../../local-storage/local-storage.service';


@Component({
  selector: 'volago-map',
  templateUrl: './map.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapComponent implements OnInit {

  @Input() mapId = '';
  @Input() style = 'height: 100%;';
  @Input() leafletLayers: Layer[] = [];

  @Output() leafletClick = new EventEmitter();
  @Output() leafletMapReady = new EventEmitter<Map>();

  zoomOptions: Control.ZoomOptions = {
    position: 'bottomleft'
  };

  readonly initOptions: MapOptions = {
    layers: [
      tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 18,
        attribution: '...',
      }),
    ],
    zoomControl: false,
    zoom: 7,
    center: latLng(51.879966, 18.026909),
  };
  leafletOptions: MapOptions = {};
  mapClicked = 0;

  constructor(
    private localStorageService: LocalStorageService,
    private chRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.leafletOptions = this.mergeMapOptions(this.initOptions, this.localStorageService.get(this.mapId));
  }

  onMapReady(m: Map): void {
    m.addControl(control.zoom({ position: 'bottomleft' }));
    
    m.on('click', (event) => {
      this.mapClicked = this.mapClicked + 1;
      setTimeout(() => {
        if (this.mapClicked === 1) {
          this.leafletClick.emit(event);
          this.mapClicked = 0;
        }
        this.chRef.detectChanges();
      }, 100);      
    });

    m.on('moveend', () => {
      this.storeMapOptions({
        center: m.getCenter(),
        zoom: m.getZoom()
      });
    });
    
    this.leafletMapReady.emit(m);
  }

  zoomin(): void {
    this.mapClicked = 0;
  }

  private storeMapOptions(point: MapOptions): void {
    this.localStorageService.set(this.mapId, point);
  }

  private mergeMapOptions(primary: MapOptions, overrided: MapOptions | null): MapOptions {
    const result = primary;

    if (overrided?.center) {
      result.center = overrided.center;
    }

    if (overrided?.zoom) {
      result.zoom = overrided.zoom;
    }

    return result;
  }

}