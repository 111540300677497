import { ImageLoaderConfig } from "@angular/common";
import { InjectionToken } from "@angular/core";

export const cloudinaryCustomLoaderFactory = (CLOUDINARY_OPTIONS: CloudinaryOptions) => {
  return (config: ImageLoaderConfig) => {
  
    const baseUrl = CLOUDINARY_OPTIONS.imageBaseUrl
    
    if (!config.loaderParams) {
      return baseUrl + '/' + config.src;
    }
    
    const queryParams = [];
    if (config.loaderParams['crop']) {
      queryParams.push(`c_${config.loaderParams['crop']}`);
    }
    if (config.loaderParams['width']) {
      queryParams.push(`w_${config.loaderParams['width']}`);
    }
    if (config.loaderParams['height']) {
      queryParams.push(`h_${config.loaderParams['height']}`);
    }
    if (config.loaderParams['gravity']) {
      queryParams.push(`g_${config.loaderParams['gravity']}`);
    }
    
    return baseUrl + queryParams.join(',') + '/' + config.src;
  };
}


export interface CloudinaryOptions {
  cloudName: string;
  imageBaseUrl: string;
}

export const CLOUDINARY_OPTIONS = new InjectionToken<CloudinaryOptions>('CLOUDINARY_OPTIONS');