import { Injectable, Signal, computed, signal } from "@angular/core";
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { PathDecorator } from "../../shared/path-decorator";
import { Administrative } from "./administrative";
import { AdministrativeDataService } from "./administrative-data.service";

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class AdministrativeService {

  administratives = signal<Administrative[]>([]);
  administrativesWithPath: Signal<PathDecorator<Administrative>[]>;
  administrativesLeavesWithPath: Signal<PathDecorator<Administrative>[]>;

  constructor(
    administrativeDataService: AdministrativeDataService
  ) {
    administrativeDataService.getAll().pipe(untilDestroyed(this)).subscribe(administratives => {
      this.administratives.set(administratives);
    });

    this.administrativesWithPath =
      computed(() => this.administratives().map(r => new PathDecorator(r, this.administratives())));

    this.administrativesLeavesWithPath =
      computed(() => {
        const leaves = this.administratives().filter(r => !this.administratives().some(r2 => r2.parent === r.id));
        return leaves.map(r => new PathDecorator(r, this.administratives()));
      });
  }

  getAdministrativePath(admId: string, includeSelf = true): string {
    const adm = this.administrativesWithPath().find(r => r.value.id === admId);
    return adm ? adm.getPath(includeSelf) : '';
  }

}



