import { Injectable } from '@angular/core';
import { Firestore } from '@angular/fire/firestore';
import { DataService } from '../../shared/service/data-service';
import { Tag } from './tag';

@Injectable({
  providedIn: 'root',
})
export class TagDataService extends DataService<Tag> {

  constructor(firestore: Firestore) {
    super(firestore, 'tags');
  }

}
