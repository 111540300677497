import { Injectable } from '@angular/core';
import { isBefore } from 'date-fns/isBefore';


@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  get<T>(key: string): T | null {
    const value = localStorage.getItem(key);
    if (!value) {
        return null;
    }
    
    return this.tryGetValue(key, value);
  }

  getOrExpire<T extends IExpireable>(key: string): T | null {
    const value = localStorage.getItem(key);
    if (!value) {
        return null;
    }
    
    const parsedValue: T | null = this.tryGetValue(key, value);
    if (parsedValue?.expire && isBefore(parsedValue.expire, new Date())) {
      this.remove(key);
      return null;
    } else {
      return parsedValue;
    }
  }

  private tryGetValue<T>(key: string, value: string): T | null {
    try {
        return JSON.parse(value);
      } catch {
        this.remove(key);
        return null;
      }
  }

  has<T>(key: string): boolean {
    const value = this.get<T>(key);
    return value !== null && value !== undefined;
  }

  hasNotExpired<T extends IExpireable>(key: string): boolean {
    const value = this.getOrExpire<T>(key);
    return value !== null && value !== undefined;
  }

  set<T>(key: string, value: T): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  remove(key: string): void {
    localStorage.removeItem(key);
  }
}

export interface IExpireable {
  expire: Date;
}
