export interface Language {
    id: string;
    name: string;
    flag: string;
    alt: string;
}

export const AVAILABLE_LANGS: Language[] = [
    { id: 'pl-PL', name: 'Polski', flag: 'pl-PL', alt: "Flaga Polski" },
    { id: 'en-GB', name: 'English', flag: 'en-GB', alt: "Flag of United Kingdom" },
];

export const CURRENT_LANG_KEY = 'lang';