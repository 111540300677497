import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Tag } from '../tag';

@Component({
  selector: 'volago-tag-list',
  templateUrl: './tag-list.component.html',
  styleUrls: ['./tag-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TagListComponent {

  @Input() tags: Tag[] = [];

}
