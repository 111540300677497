export class PathDecorator<T extends { id: string; name: string; parent?: string; }> {
  value: T;
  private path = '';
  private ancestors: T[] = [];

  constructor(v: T, vs: T[]) {
    this.value = v;
    this.calculateAncestors(v, vs);
  }

  getPath(includeSelf = true): string {
    const as: T[] = [];
    this.ancestors.forEach(val => as.push(Object.assign({}, val)));

    if (!includeSelf) {
      as.shift();
    }

    return as.map(r => r.name).reverse().join(' > ');
  }


  private calculateAncestors(region: T, regions: T[]): void {
    this.ancestors = [region];

    while (region?.parent) {
      const parent = regions.filter(r => r.id === region.parent)[0];
      if (parent) {
        this.ancestors.push(parent);
      }
      region = parent;
    }
  }
}


