import { Injectable } from '@angular/core';
import { VolagoMarker } from './marker';
import { Firestore } from '@angular/fire/firestore';
import { DataService } from '../../shared/service/data-service';

@Injectable({
  providedIn: 'root',
})
export class MarkerDataService extends DataService<VolagoMarker> {

  constructor(firestore: Firestore) {
    super(firestore, 'markers');
  }

}
