import { collection, collectionData, CollectionReference, doc, docData, DocumentData, Firestore, query, setDoc, updateDoc, where } from '@angular/fire/firestore';
import { Observable, of } from 'rxjs';


export abstract class DataService<T extends { id: string; }> {

  private collection: CollectionReference<DocumentData>;

  constructor(private firestore: Firestore, private collectionName: string) {
    this.collection = collection(this.firestore, this.collectionName);
  }

  getAll(): Observable<T[]> {
    return collectionData(this.collection, {
      idField: 'id',
    }) as Observable<T[]>;
  }

  get(id: string): Observable<T> {
    const ref = doc(this.firestore, `${this.collectionName}/${id}`);
    return docData(ref, { idField: 'id' }) as Observable<T>;
  }

  getMany(ids: string[]): Observable<T[]> {
    if (!ids || ids.length === 0) {
      return of([]);
    }
    const q = query(this.collection, where('id', 'in', ids))
    return collectionData(q) as Observable<T[]>;
  }

  save(item: T): Promise<void> {
    const d = doc(this.firestore, `${this.collectionName}/${item.id}`);
    return updateDoc(d, { ...item, modifyDate: new Date() });
  }

  add(item: T): Promise<void> {
    const d = doc(this.firestore, `${this.collectionName}/${item.id}`);
    return setDoc(d, { ...item, createDate: new Date(), modifyDate: new Date() });
  }
}
