import { Injectable } from '@angular/core';
import { Region } from './region';
import { Firestore } from '@angular/fire/firestore';
import { DataService } from '../../shared/service/data-service';

@Injectable({
  providedIn: 'root',
})
export class RegionDataService extends DataService<Region> {

  constructor(firestore: Firestore) {
    super(firestore, 'regions');
  }

}
